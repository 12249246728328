import React from "react";
import "../footer/footer.css";
import BannerPirate from "../../assets/img/APRE/BannerBawah.jpg";

const Footer = () => {
  return (
    <footer>
      <img className="banner-bawah" src={BannerPirate} alt="banner" />
    </footer>
  );
};

export default Footer;
