import React, { useEffect, useRef, useState } from 'react'
import '../location/location.css'

const Location = () => {

    return (
        <section className="location" id='lokasi'>
            <div className='row text-center'>
                <div className='col-lg-12'>
                    <h3>27 September 2024</h3>
                    <h4>Friday, 15:00 - 17:30 PM</h4>
                </div>
            </div>
            <br/>
            <div className='row text-center'>
                <div className='col-lg-12'>
                    <h4>Venue : </h4>
                    <h3>IHMS Campus</h3>
                    <h4>Jl. Buluh Indah No. 56, Pemecutan Kaja,</h4>
                    <h4>Kec. Denpasar Utara, Kota Denpasar, Bali 80118</h4>
                    <h4>Dress Code: "NUSANTARA" (Traditional Clothes)</h4>
                </div>
            </div>
        </section>
    )
}

export default Location