import React, { useEffect, useState } from "react";
import "../header/header.css";
import BannerPirate from "../../assets/img/APRE/Header.jpg";

const Navbar = () => {
  return (
    <div className="header" id="header">
      <img className="banner-pirate" src={BannerPirate} alt="banner" />
    </div>
  );
};

export default Navbar;
