import React from "react";
import "../about/about.css";

const About = () => {
  return (
    <>
      <div className="about" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <p>
                Established in 2017, our school is an official partner of Melbourne Polytechnic and we have been at the forefront of innovative teaching, and holistic development. Nestled in the heart of Bali, we offer state-of-the-art facilities, a diverse and inclusive community, and an Australian curriculum that challenges and inspires our students.
              </p>  
              <p>
                Our dedicated faculty, and strong emphasis on values and character development ensure that each student is prepared to thrive in a rapidly changing world within the Hospitality Industry. At IHMS, we are committed to nurturing leaders, innovators, and compassionate global citizens.
              </p>
              <h3 className="text-center">“From Bali to the World”</h3>
              <p>
                We are delighted to invite you to the grand opening of IHMS new building. This facility represents our continued commitment to providing exceptional educational opportunities and fostering a vibrant community.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
